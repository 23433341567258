import React, {Component} from 'react';
import Photo from './Photo'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import './styles/event.scss';

class Event extends Component {
    render() {
        return (
            <div className="event">
                <Row>
                    <Col xl={12} className="text-center text-uppercase">
                        <h2 className="mb-4"><span className="title">Veranstaltungstipps</span> Leipzig</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xl={3}>
                        <h5 className="date text-center">
                            <Moment className="pb-1 pt-1 d-none d-xl-block" tz="Europe/Berlin" format="dddd, Do MMM">{this.props.event.date}</Moment>
                            <Moment className="pb-1 pt-1 d-xl-none" tz="Europe/Berlin" format="dddd, Do MMMM">{this.props.event.date}</Moment>
                        </h5>
                    </Col>
                    <Col xl={9}>
                        <h3 className="text-center text-xl-left">
                            <div className="pb-1 pt-1">{this.props.event.title}</div>
                        </h3>
                    </Col>
                </Row>
                <Row className="infos text-center text-xl-left">
                    {this.props.event.photo && (
                        <Col xl={3}>
                            <Photo url={this.props.event.photo.path}/>
                            {this.props.event.copyright && (
                                <small className="text-muted text-center d-none d-xl-block pl-1 pr-1 overflow-hidden">
                                    <small>
                                        <small>
                                            <small>&copy; copyright: {this.props.event.copyright}</small>
                                        </small>
                                    </small>
                                </small>
                            )}
                        </Col>
                    )}
                    <Col xl={{span: 6, offset: this.props.event.photo ? 0 : 3}}>
                        <Row>
                            <Col md xl={4}>
                                <h5 className="mt-3 text-uppercase">Zeit</h5>
                                <span className="time" tz="Europe/Berlin" format="H:mm [Uhr]">{this.props.event.time} Uhr</span>
                            </Col>
                            <Col md xl>
                                <h5 className="mt-3 text-uppercase">Ort</h5>
                                <div className="location">{this.props.event.location}</div>
                            </Col>
                            {this.props.event.price && (
                                <Col md xl>
                                    <h5 className="mt-3 text-uppercase">Preis</h5>
                                    <div className="price">{this.props.event.price}</div>
                                </Col>
                            )}
                        </Row>
                        <div>
                            <h5 className="mt-3 text-uppercase">Beschreibung</h5>
                            <p className="description">{this.props.event.description}</p>
                        </div>
                    </Col>
                </Row>
                <div className="fixed-bottom">
                    <Row>
                        <Col sm className="text-nowrap text-uppercase simplioffice-logo">
                            <small><img src="/images/simplioffice-logo.png" srcSet="/images/simplioffice-logo.png 1x, /images/simplioffice-logo@2x.png 2x" alt="SimpliOffice Logo" width="57" height="57"/> {this.props.event.category}</small>
                        </Col>
                        <Col sm className="text-right urbanite-logo">
                            <small>powered by <img src="/images/urbanite-logo.png" srcSet="/images/urbanite-logo.png 1x, /images/urbanite-logo@2x.png 2x" alt="Urbanite Logo" width="192" height="57"/></small>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Event;