import React, {Component} from 'react';
import Carousel from './Carousel';
import Loader from './Loader';
import moment from 'moment';
import buildUrl from 'build-url';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            results: []
        }
    }

    async componentDidMount() {
        let today = moment().endOf('day').subtract(1, 'day').format();
        let url = buildUrl(process.env.REACT_APP_COCKPIT_URL, {
            path: 'api/collections/get/events',
            queryParams: {
                "sort[date]": 1,
                "sort[time]": 1,
                "filter[date][$gte]": today
            }
        });
        console.log(url);
        let response = await fetch(url);
        if (!response.ok) {
            return
        }

        let result = await response.json();
        this.setState({loading: false, results: result.entries})
    }

    render() {
        if (!this.state.loading) {
            if (this.state.results.length === 0) {
                return (<div/>);
            }

            return (
                <Carousel events={this.state.results}/>
            );
        }

        return (<Loader/>);
    }
}

export default List;