import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';
import buildUrl from 'build-url';


class Photo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            url: []
        }
    }

    async componentDidMount() {
        if (this.props.url && this.props.url.startsWith("https://simplioffice.s3.amazonaws.com")) {
            let baseURL = process.env.REACT_APP_COCKPIT_URL;
            let url = buildUrl(baseURL, {
                path: 'api/imagestyles/style/EventPhoto',
                queryParams: {
                    token: process.env.REACT_APP_COCKPIT_TOKEN,
                    src: this.props.url
                }
            });

            let response = await fetch(url);
            if (!response.ok) {
                return
            }

            let result = await response.text();
            this.setState({loading: false, url: baseURL + result})
        } else {
            this.setState({loading: false, url: this.props.url})
        }
    }

    render() {
        if (!this.state.loading) {
            return (
                <Image src={this.state.url} fluid className="d-none d-xl-block mt-3"/>
            );
        }

        return null;
    }
}

export default Photo;