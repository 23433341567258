import React, {Component} from 'react';

class Loader extends Component {
    render() {
        return (
            <table className="vh-100 w-100">
                <tbody>
                <tr>
                    <td className="text-center align-middle">wird geladen ...</td>
                </tr>
                </tbody>
            </table>
        );
    }
}

export default Loader;