import React, {Component} from 'react';
import {BrowserRouter,Route,Redirect,Switch} from 'react-router-dom';
import 'bootstrap/scss/bootstrap.scss';
import Preview from './Preview'
import List from './List'

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div className="app vh-100">
                    <Switch>
                        <Route path="/" exact component={List}/>
                        <Route path="/preview" exact component={Preview}/>
                        <Redirect to="/"/>
                    </Switch>
                </div>
            </BrowserRouter>

        );
    }
}

export default App;
