import React, {Component} from 'react';
import InheritCarousel from 'react-bootstrap/Carousel'
import Event from './Event';
import './styles/carousel.scss';

class Carousel extends Component {
    render() {
        return (
            <InheritCarousel className="vh-100" controls="false" interval="6000" prevIcon="" nextIcon="" indicators="false" fade="true">
                {this.props.events.map((event, index) => {
                    return (
                        <InheritCarousel.Item key={index.toString()} className="item mh-100">
                            <Event event={event}/>
                        </InheritCarousel.Item>
                    );
                })}
            </InheritCarousel>
        );
    }
}

export default Carousel;