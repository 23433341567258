import React, {Component} from 'react';
import Carousel from './Carousel';
import Loader from './Loader';

class Preview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            event: null
        }
    }

    async componentDidMount() {
        let that = this;
        window.addEventListener('message', function (event) {
            that.setState({loading: false, event: event.data.entry})
        }, false);
    }

    render() {
        if (!this.state.loading) {
            return (
                <Carousel events={new Array(this.state.event)}/>
            )
        }

        return (<Loader/>);
    }
}

export default Preview;